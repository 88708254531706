export default {
  apiKey: "AIzaSyDnnjO4R3KDXAhlXpp_DLZjWknb8-7e4lo",

  authDomain: "inpec-cc9bb.firebaseapp.com",

  projectId: "inpec-cc9bb",

  storageBucket: "inpec-cc9bb.appspot.com",

  messagingSenderId: "1034929339582",

  appId: "1:1034929339582:web:3e018f11d0bfbfcfe072ef",
};

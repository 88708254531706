import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/compat/auth";
import firebaseConfig from "./../firebase/firebaseConfig";
firebase.initializeApp(firebaseConfig);
Vue.use(VueRouter);
const auth = firebase.auth();
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: () => import("@/views/Home.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },

    {
      path: "/product",
      name: "product",

      component: () => import("@/views/Products/Product.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "Product",
        breadcrumb: [
          {
            text: "Product",
            active: true,
          },
        ],
      },
    },
    {
      path: "/EditProduct/:id",
      name: "Edit-Product",
      component: () => import("@/views/Products/EditProduct.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "Modification Product",
        breadcrumb: [
          {
            text: "Edit Product",
            active: true,
          },
        ],
      },
    },
    {
      path: "/EditProductVar/:id",
      name: "Edit-Product-Variable",
      component: () => import("@/views/Products/EditProductVariable.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "Modification Product",
        breadcrumb: [
          {
            text: "Liste Produits",
            to: "/product",
            active: false,
          },

          {
            text: "Edit Product",
            active: true,
          },
        ],
      },
    },
    {
      path: "/AddProductSimple",
      name: "Add Product Simple",

      component: () => import("@/views/Products/AddProductSimple.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "Add Product Simple",
        breadcrumb: [
          {
            text: "Add Product Simple",
            active: true,
          },
        ],
      },
    },

    {
      path: "/AddProductVariable",
      name: "Add Product Variable",
      component: () => import("@/views/Products/AddProductVariable.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "Add Product Variable",
        breadcrumb: [
          {
            text: "Add Product Variable",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Categories",
      name: "Categories",

      component: () => import("@/views/Categories/Categories.vue"),
      meta: {
        requiresAdmin: true,
        breadcrumb: [
          {
            text: "Categories",
            active: true,
          },
        ],
      },
    },
    {
      path: "/AddCategorie",
      name: "Add Category",
      component: () => import("@/views/Categories/AddCategorie.vue"),
      meta: {
        pageTitle: "Add Category",
        breadcrumb: [
          {
            text: "Add Category",
            active: true,
          },
        ],
      },
    },
    {
      path: "/EditCategorie/:id",
      name: "Edit Categorie",
      component: () => import("@/views/Categories/EditCategorie.vue"),
      meta: {
        pageTitle: "Edit Categorie",
        breadcrumb: [
          {
            text: "Liste Des Categories",
            to: "/Categories",
            active: false,
          },

          {
            text: "Edit Categorie",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Etiquette",
      name: "Etiquette",
      component: () => import("@/views/Etiquettes/Etiquette.vue"),
      meta: {
        pageTitle: "Etiquette",
        breadcrumb: [
          {
            text: "Etiquette",
            active: true,
          },
        ],
      },
    },
    {
      path: "/EditEtiquette/:id",
      name: "Edit Etiquette",
      component: () => import("@/views/Etiquettes/EditEtiquette.vue"),
      meta: {
        pageTitle: "Edit Etiquette",
        breadcrumb: [
          {
            text: "Liste Des Etiquettes",
            to: "/Etiquette",
            active: false,
          },
          {
            text: "Edit Etiquette",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Marque",
      name: "Marque",
      component: () => import("@/views/Marques/ListeMarque.vue"),
      meta: {
        breadcrumb: [
          {
            text: "Liste Des Marques",
            active: true,
          },
        ],
      },
    },
    {
      path: "/AddMarque",
      name: "Add Marque",
      component: () => import("@/views/Marques/AddMarque.vue"),
      meta: {
        pageTitle: "Nouvelle Marque",
        breadcrumb: [
          {
            text: "Nouvelle Marque",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Attributes",
      name: "Attributes",

      component: () => import("@/views/Attributes/Attributes.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "Attributes",
        breadcrumb: [
          {
            text: "Attributes",
            active: true,
          },
        ],
      },
    },
    {
      path: "/AddAttributes",
      name: "AddAttributes",

      component: () => import("@/views/Attributes/AddAttributes.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "AddAttributes",
        breadcrumb: [
          {
            text: "Add Attributes",
            active: true,
          },
        ],
      },
    },
    {
      path: "/ConfigureTerm/:id",
      name: "Configure Terme",

      component: () => import("@/views/Attributes/ConfigureTerm.vue"),
      meta: {
        requiresAdmin: true,
        pageTitle: "Configure Terme",
        breadcrumb: [
          {
            text: "Liste Des Attributs",
            to: "/AddAttributes",
            active: false,
          },
          {
            text: "Configure Term",
            active: true,
          },
        ],
      },
    },
    {
      path: "/EditMarque/:id",
      name: "Edit Marque",
      component: () => import("@/views/Marques/EditMarque.vue"),
      meta: {
        pageTitle: "Edit Marque",
        breadcrumb: [
          {
            text: "Liste Des Marques",
            to: "/Marque",
            active: false,
          },
          {
            text: "Edit Marque",
            active: true,
          },
        ],
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAdmin);
  if (requiresAuth && !(await firebase.getCurrentUser())) {
    next("login");
  } else {
    next();
  }
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
